import React from "react";

// ActionTypes
import {
  ADD_ALL_USERS_TO_PAGE_BUILDER_TARGET_GROUP,
  ADD_USER_TO_PAGE_BUILDER_TARGET_GROUP,
  GET_PAGE_BUILDER_PAGES,
  GET_PAGE_BUILDER_PAGES_FAILURE,
  GET_PAGES_BUILDER_PAGES_SUCCESS,
  PAGE_BUILDER_AUTH_FAILURE,
  PAGE_BUILDER_AUTH_SUCCESS,
  PAGE_BUILDER_CHANGE_HANDLER_FAILURE,
  PAGE_BUILDER_CHANGE_HANDLER_SUCCESS,
  PAGE_BUILDER_UPDATE_CURRENT_PARENT_PAGE_ID,
  PAGE_BUILDER_UPDATE_PAGES,
  PAGE_BUILDER_UPDATE_PAGES_FAILURE,
  PAGE_BUILDER_UPDATE_PAGES_SUCCESS,
  REMOVE_ALL_USERS_FROM_PAGE_BUILDER_TARGET_GROUP,
  REMOVE_USER_FROM_PAGE_BUILDER_TARGET_GROUP,
} from "./actionTypes";

// Utilities
import req from "../utilities/request-utility";
import { filterModifiedPages } from "../components/pageBuilder/utilities/filterModifiedPages";
import { formatPageBuilderPages } from "../components/pageBuilder/utilities/formatPageBuilderPages";

// Actions
import { addToast } from "./uiActions";

// Components
import { CheckIcon, ErrorIcon } from "mdi-react";

export function getPageBuilderPages() {
  return async function (dispatch) {
    try {
      dispatch({ type: GET_PAGE_BUILDER_PAGES });

      const { data: pages } = await req()(`admin/pages`);

      dispatch({ type: GET_PAGES_BUILDER_PAGES_SUCCESS, payload: formatPageBuilderPages(pages) });
      dispatch({ type: PAGE_BUILDER_AUTH_SUCCESS });
    } catch (error) {
      if (error.response?.status === 403) {
        dispatch({ type: PAGE_BUILDER_AUTH_FAILURE });
      } else {
        dispatch({ type: GET_PAGE_BUILDER_PAGES_FAILURE });
      }
    }
  };
}

export function pagesChangeHandler({ pages }) {
  return function (dispatch) {
    try {
      dispatch({ type: PAGE_BUILDER_CHANGE_HANDLER_SUCCESS, payload: pages });
    } catch (error) {
      dispatch({ type: PAGE_BUILDER_CHANGE_HANDLER_FAILURE });
    }
  };
}

export function updatePages() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: PAGE_BUILDER_UPDATE_PAGES });

      const { pages: reduxPages } = getState().pageBuilder;

      // Filter to only include pages that has been modified
      const pagesToUpdate = filterModifiedPages({ pages: reduxPages });

      const { data: pages } = await req().put(`admin/pages`, { pages: pagesToUpdate });

      addToast({
        title: "Pages has successfully been updated",
        content: "",
        icon: <CheckIcon />,
        styleType: "success",
        duration: 5000,
      })(dispatch, getState);

      dispatch({ type: PAGE_BUILDER_UPDATE_PAGES_SUCCESS, payload: formatPageBuilderPages(pages) });
    } catch (error) {
      dispatch(addToast({ template: "error" }));
      dispatch({ type: PAGE_BUILDER_UPDATE_PAGES_FAILURE });
    }
  };
}

export function updateParentPageId({ parentPageId }) {
  return function (dispatch) {
    dispatch({ type: PAGE_BUILDER_UPDATE_CURRENT_PARENT_PAGE_ID, payload: parentPageId });
  };
}

export function addUserToPageBuilderTargetGroup({ pageId, user }) {
  return function (dispatch) {
    dispatch({
      type: ADD_USER_TO_PAGE_BUILDER_TARGET_GROUP,
      payload: { pageId, user },
    });
  };
}

export function removeUserFromPageBuilderTargetGroup({ pageId, user }) {
  return function (dispatch) {
    dispatch({
      type: REMOVE_USER_FROM_PAGE_BUILDER_TARGET_GROUP,
      payload: { pageId, user },
    });
  };
}

export function addAllUsersToPageBuilderTargetGroup({ pageId, users }) {
  return function (dispatch) {
    dispatch({
      type: ADD_ALL_USERS_TO_PAGE_BUILDER_TARGET_GROUP,
      payload: { pageId, users },
    });
  };
}

export function removeAllFromPageBuilderTargetGroup({ pageId }) {
  return function (dispatch) {
    dispatch({
      type: REMOVE_ALL_USERS_FROM_PAGE_BUILDER_TARGET_GROUP,
      payload: { pageId },
    });
  };
}

export function authenticate({ password }) {
  return async function (dispatch, getState) {
    try {
      await req().post(`/admin/pages/authenticate`, { password });

      dispatch({ type: PAGE_BUILDER_AUTH_SUCCESS });
      dispatch(getPageBuilderPages());
    } catch (error) {
      dispatch({ type: PAGE_BUILDER_AUTH_FAILURE });

      addToast({
        title: "Unable to authenticate",
        content: "",
        icon: <ErrorIcon />,
        styleType: "error",
        duration: 5000,
      })(dispatch, getState);
    }
  };
}
